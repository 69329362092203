export default {
  h1FontFamily: 'Subjectivity',
  desktopTitlePadding: '5%',
  textBlockMobileDisplay: 'block',
  textBlockMobileFontWeight: 'bold',
  textBlockDesktopFontWeight: 'bold',
  campaignWrapperBackground: 'var(--white)',
  campaignTextColor: 'var(--black)',
  formBlockColor: 'var(--white)',
  mobileAspectRatio: '17 / 9',
  tabletAspectRatio: '20 / 9',
  desktopAspectRatio: '26 / 9',
  maxWrapperWidth: 'auto',
  maxWrapperWidthDownFromDesktop: 'auto',
  maxWrapperWidthUpFromLargeDesktop: 'auto',
  layoutPadding: '5rem 5% 1rem',
  disclaimerColor: 'var(--white)',
  topTitlePadding: '1rem',
  foregroundTranslate: 'translateX(-28vw) scale(0.65)',
  foregroundMobileTranslate: 'translateX(65vw) translateY(-1rem) scale(1.5)',
  mobileForegroundDisplay: 'none',
};
